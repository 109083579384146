import bmr from "./bmr/theme";
import cert from "./cert/theme";
import { createTheme } from "@mui/material/styles";

const brandThemeMap = {
  bmr,
  cert,
};

export const modeTheme = (mode) => {
  const brand = process.env.REACT_APP_BRAND;
  const palettes = brandThemeMap[brand] || bmr;
  return createTheme({
    deck: {
      easy: { main: "#2e7d32" },
      normal: { main: "#0288d1" },
      hard: { main: "#d32f2f" },
    },
    palette: {
      mode: mode,
      ...(mode === "dark" ? palettes.dark : palettes.light),
    },
  });
};
