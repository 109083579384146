import React from "react";
import NavigationBmr from "./NavigationBmr";
import NavigationCert from "./NavigationCert";
import { Page } from "advancement-solutions-components/dist/components";

const Component = ({ user }) => {
  const brand = process.env.REACT_APP_BRAND;
  return (
    <Page user={user}>
      {brand === "bmr" && <NavigationBmr />}
      {brand === "cert" && <NavigationCert />}
    </Page>
  );
};

export default Component;
